<template>
  <div>
<!--    <el-row type="flex">-->
<!--      <el-col :span="4">-->
<!--        <div-->
<!--            v-for="(anchor,index) in titles"-->
<!--            :key="index"-->
<!--            :style="{ padding: `10px 0 10px ${anchor.indent * 20}px` }"-->
<!--            @click="handleAnchorClick(anchor)"-->
<!--        >-->
<!--        </div>-->
<!--      </el-col>-->
<!--      <el-col :span="20">-->
<!--        <v-md-preview id="v-md-preview"-->
<!--                      :text="text"-->
<!--                      class="pre"-->
<!--                      ref="preview"-->
<!--                      @change="change(arguments)"-->
<!--        >-->

<!--        </v-md-preview>-->
<!--      </el-col>-->
<!--    </el-row>-->
    <v-md-preview id="v-md-preview"
                  :text="text"
                  class="pre"
                  ref="preview">

    </v-md-preview>
  </div>
</template>

<script>
export default {
  name: "md-blog",
  props:{
    text:{
      type:String
    }
  },
  data(){
    return{
      titles:[]
    }
  },
  mounted() {

  },
  methods: {
    change(msg){
      if(document.getElementById("v-md-preview")!==null){
        // const anchors = this.$ref.preview.$el.querySelectorAll('h1,h2,h3,h4,h5,h6');
        // console.log(document.getElementById("v-md-preview"))
        const anchors=document.getElementById("v-md-preview").querySelectorAll('h1,h2,h3,h4,h5,h6');
        console.log(anchors)
        const titles = Array.from(anchors).filter((title) => !!title.innerText.trim());
        if (!titles.length) {
          this.titles = [];
          return;
        }
        const hTags = Array.from(new Set(titles.map((title) => title.tagName))).sort();
        this.titles = titles.map((el) => ({
          title: el.innerText,
          lineIndex: el.getAttribute('data-v-md-line'),
          indent: hTags.indexOf(el.tagName),
        }));
      }
    },
    handleAnchorClick(anchor) {
      const { preview } = this.$refs;
      const { lineIndex } = anchor;

      const heading = preview.$el.querySelector(`[data-v-md-line="${lineIndex}"]`);

      if (heading) {
        preview.scrollToTarget({
          target: heading,
          scrollContainer: window,
          top: 60,
        });
      }
    },
  },
}
</script>

<style scoped>
.pre{
  text-align: left;
}
</style>