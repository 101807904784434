<template>
  <div class="recent">
    <h1 class="tit">最近的文章</h1>
    <ul class="article-newest">
      <li v-for="(item ,index) in con" :key="index" >
        <span :class="index<=2 ?'order-first':'order'">{{ index + 1 }} </span>
        <a href="https://www.baidu.com">
          {{ item }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "recent",
  data() {
    return {
      con: [
        "sdadad",
        "sdadjuwq90是大大大大大大阿萨哈湖大ui",
        "飒飒的豪华度",
        "sjaidj",
      ]
    }
  }
}
</script>

<style scoped>
.recent{
  margin: 20px auto;
}
.tit {
  display: inline-block;
  /*background: #f0f1f4;*/
  padding: 0 8px;
  font-size: 16px;
  color: #333;
  font-weight: 600;
}
/* 前面的标签*/
.order-first {
  background-color: #ec4141;
  color: #fff;
  border-radius: 2px;
  margin-top: 1px;
  font-size: 13px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  width: 18px;
  line-height: 18px;
  height: 18px;
  box-sizing: border-box;
}



.order {
  font-size: 13px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  width: 18px;
  height: 18px;
  line-height: 18px;
  background-color: #bebebe;
  color: #fff;
  border-radius: 2px;
  margin-top: 1px;
  box-sizing: border-box;
}

li {
  text-align: left;
  margin-top: 5px;
  list-style: none;
  display: flex;
}
.article-newest li a {
  margin-left: 5px;
  width: 90%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 20px;
  color: #333;
}


</style>