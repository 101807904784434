<template>
  <div class="blog-show">
    <el-row :gutter="18" type="flex">
      <!--      右边-->
      <el-col :span="6" class="left">
        <div class="author-face">
          <el-avatar class="face-img" :src="article.userImg"></el-avatar>
        </div>

        <div class="author-name">{{ article.username }}</div>
        <div class="author-one">

        </div>
        <div class="author-article-pl">

        </div>
        <recent></recent>
      </el-col>
      <!--      左边-->
      <el-col :span="20" class="right">
        <h1 class="article-title">
          {{ article.articleTitle }}
        </h1>

        <div class="article-bar-top">
          <el-row type="flex" class="bar-four">
            <div class="bar-content">
              <i class="fa fa-eye"></i>
              {{ article.articleViews }}浏览量
            </div>
            <div class="bar-content" @click="clickGood">
              <i class="fa fa-thumbs-o-up"></i>
              {{ article.articleGood }}点赞
            </div>
            <div class="bar-content" @click="clickCollect">
              <i class="fa fa-star"></i>
              {{ article.articleStar }}收藏
            </div>
            <div class="bar-content">
              <i class="fa fa-thumbs-o-down"></i>
              {{ article.articleBad }}踩
            </div>
          </el-row>
          <el-row v-if="tags" class="bar-tags">
            <el-tag class="article-tag" effect="dark" v-for="(item,index) in tags" :key="index">
              {{ item }}
            </el-tag>
          </el-row>
        </div>
        <el-row class="article-content">
          <md-blog :text="article.articleContext"></md-blog>
        </el-row>
        <el-row>
          <Comment v-if="articleId" :id="articleId" :type="1"></Comment>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {getArticleById} from "@/api/article";
import {good} from "@/api/good";
import {collect} from "@/api/collect";

import Comment from "@/components/comment/Comment";
import mdBlog from "@/views/blog/md-blog";
import Recent from "@/views/blog/recent";
//展示 页面的插件
export default {
  name: "Show",
  components: {
    Recent,
    Comment,
    mdBlog
  },
  data() {
    return {
      value: "",
      article: "",
      tags: [],
      articleId: "",
      titles: [],
    }
  },
  mounted() {
    this.change();
  },
  methods: {
    clickGood() {
      console.log("点赞了")
      let go = {
        userId: this.$store.state.user.id,
        pid: this.articleId,
        type: 1
      }
      good(go).then(result => {
        this.$message({
          type: "success",
          duration: 2 * 1000,
          message: result.data.good
        })
      }).catch(error => {
        this.$message({
          type: "error",
          duration: 2 * 1000,
          message: error.message
        })
      })
    },
    // 点击收藏
    clickCollect() {
      let co = {
        userId: this.$store.state.user.id,
        pid: this.articleId,
        type: 1
      }
      collect(co).then(result => {
        this.$message({
          type: "success",
          duration: 2 * 1000,
          message: result.data.collect
        })
      }).catch(error => {
        this.$message({
          type: "error",
          duration: 2 * 1000,
          message: error.message
        })
      })
    },
    // 获取文章
    change() {
      this.articleId = this.$route.query.articleId
      const loading = this.$loading({
        lock: true,
        text: '正在加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      getArticleById(this.articleId).then(result => {
        console.log(result)
        this.article = result.data.article;
        this.tags = this.article.tags.split(",");
        loading.close();
      }).catch(error => {
        loading.close();
        this.$message({
          type: "error",
          message: error.message,
          duration: 2 * 1000
        })
      })
    }
  }
}
</script>

<style scoped>
.blog-show {
  width: 85%;
  margin: auto;
  padding: 10px;
  height: 100%;
  min-height: calc(100vh - 60px);
}
.author-name{
  font-size: 18px;
  margin: 15px 0;
  font-weight: 600;
}
.author-face{
  height: 100px;
  display: flex;
}
.face-img{
  margin: auto;
  height: 84px;
  width: 84px;
}

/* 左边*/
.left {
  background-color: #fff;
  margin-right: 10px;
}

/*左边的内容*/
.right {
  background-color: #fff;
}

/* 首页大标题*/

.article-title {
  /*width: 90%;*/
  height: 40px;
  padding: 4px 2rem;
  line-height: 40px;
  font-size: 1.75rem;
  word-wrap: break-word;
  color: #222226;
  font-weight: 600;
  margin: 0 auto 10px;
  word-break: break-all;
  text-align: left;
}

/* 标签*/
.article-bar-top {
  /*background-color: #f8f8f8;*/
  padding: 5px 2rem;
  margin: 0 auto 10px;
}

.bar-four {
  line-height: 40px;
  height: 40px;
}

.bar-four div {
  margin-right: 10px;
}

.article-tag {
  margin-right: 10px;
  display: inline-block;
}

.bar-tags {
  display: flex;
  line-height: 40px;
  height: 40px;
}


.bar-content {
  color: #858585;
}

.bar-content:hover {
  background-color: #ffffff;
  color: black;
}

.markdown-body {
  box-sizing: border-box;
  min-width: 200px;
  max-width: 980px;
  margin: 0 auto;
  padding: 45px;
  text-align: left;
}


</style>
