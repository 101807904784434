import { render, staticRenderFns } from "./recent.vue?vue&type=template&id=3cee6644&scoped=true&"
import script from "./recent.vue?vue&type=script&lang=js&"
export * from "./recent.vue?vue&type=script&lang=js&"
import style0 from "./recent.vue?vue&type=style&index=0&id=3cee6644&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cee6644",
  null
  
)

export default component.exports